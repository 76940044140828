<template>
  <div class="sld_settle_mailbox">
    <div class="sld_mailbox">
      <h3 class="h3_tit">供应商信息</h3>
      <div class="mailbox_email">
        <!-- 店铺名称 start -->
        <div class="basics_info_box">
          <div class="basics_info_tit">
            <span>邮箱:</span>
          </div>
          <div class="basics_info_inp_box">
            <div class="basics_info_inp">
              <input
                type="text"
                name=""
                id=""
                v-model="email"
                placeholder="请输入邮箱"
              />
            </div>
            <p>
              请输入邮箱，点击发送，根据邮件内容完成开户，开户完成后完成供应商申请。
            </p>
          </div>
          <!-- <div class="basics_info_btn" @click="nextStep">
              发送
            </div> -->
          <div class="basics_info_btn" @click="nextStep" :class="{'basics_info_btns':countDownM!=0}">
            {{ countDownM ? countDownM + L["s后获取"] : '发送' }}
          </div>
        </div>
        <!-- 店铺名称 end -->
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, watchEffect, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import SldCommonEmpty from "@/components/SldCommonEmpty";
export default {
  name: "AccountList",
  components: { SldCommonEmpty },
  setup() {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const countDownM = ref(0); //短信验证码倒计时
    const timeOutId = ref('');//定时器的返回值
    const preventFre = ref(false)
    const L = proxy.$getCurLanguage();
    const mailFlag = ref(true);
    // 邮箱
    const email = ref("");

    //倒计时
    const countDown = () => {
      countDownM.value--;
      if(countDownM.value == 0){
        preventFre.value = false;
        clearTimeout(timeOutId.value);
      } else {
        timeOutId.value = setTimeout(countDown, 1000);
      }
    };
    //  发送按钮事件
    const nextStep = () => {
      if (!email.value) {
        ElMessage("请输入邮箱");
        return;
      } else {
        let Email = proxy.$checkEmail(email.value);
        if (Email !== true) {
          ElMessage.warning("请输入正确的邮箱");
          return false;
        }
      }
      if(countDownM.value!=0){
        return
      }
      if(mailFlag.value){
        nextStep_o()
      }else{
        sendEmail()
      }
      
      return;
      router.push({
        path: "/member/settle/results",
      });
    };
    const nextStep_o = ()=>{
      localStorage.setItem('saveApply3',email.value)
      let saveApply1 = JSON.parse(localStorage.getItem("saveApply1"));
      let saveApply2 = JSON.parse(localStorage.getItem("saveApply2"));
      let saceapply = Object.assign(saveApply1, saveApply2);
      saceapply.vendorExtend.enterpriseBrandJsonList.forEach((item) => {
        if (item.enterpriseBrandIsTime) {
          item.enterpriseBrandCreateTime = "";
          item.enterpriseBrandEndTime = "";
          item.enterpriseBrandIsTime = 1;
        } else {
          item.enterpriseBrandIsTime = 0;
        }
      });
      saceapply.vendorExtend.enterpriseBrandJsonList = JSON.stringify(
        saceapply.vendorExtend.enterpriseBrandJsonList
      );
      saceapply.preGoodsCategoryIds = JSON.stringify(
        saceapply.preGoodsCategoryIds
      );
      saceapply.preCateAuditPick = JSON.stringify(saceapply.preCateAuditPick);

      let params = {};
      params = saceapply;
      params.vendorExtend.enterpriseEmail = email.value
      console.log(params);
      proxy
        .$post("v3/member/front/member/saveApply", params, "application/json")
        .then((res) => {
          if (res.state == 200) {
            ElMessage.success(res.msg)
            localStorage.removeItem('saveApply1')
            localStorage.removeItem('saveApply2')
            localStorage.removeItem('saveApply3')
            mailFlag.value = false
            sendEmail()
          } else {
            ElMessage.success(res.msg)
            countDownM.value = 60;
            countDown()
          }
        });
    }

    const sendEmail = ()=>{
      proxy.$get('/v3/member/front/member/sendEmail', { email:email.value }).then(res => {
          if (res.state == 200) {
            // ElMessage.warning(res.msg)
            countDownM.value = 60;
            countDown()
          } else {
              ElMessage.warning(res.msg)
          }
          })
    }
    onMounted(() => {
      if(localStorage.getItem('saveApply3')){
        email.value = localStorage.getItem('saveApply3')
      }
    });

    return {
      L,
      email,
      nextStep,
      nextStep_o,
      countDownM,
      countDown,
      timeOutId,
      preventFre,
      mailFlag,
      sendEmail
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/member/mailbox.scss";
</style>
<style lang="scss">
</style>
